function applyPlaceholders(variable, placeholders) {
	if (typeof variable === 'string') {
		return _replacePlaceholders(variable, placeholders);
	}

	if (typeof variable === 'object' && variable !== null) {
		if (Array.isArray(variable)) {
			return variable.map((s) => applyPlaceholders(s, placeholders));
		}

		return Object.keys(variable).reduce((agg, key) => {
			agg[key] = applyPlaceholders(variable[key], placeholders);

			return agg;
		}, {});
	}

	return variable;
}

function _replacePlaceholders(str, placeholders) {
	return str.replace(/%%([^%]+)%%/g, (match, key) => {
		if (typeof placeholders[key] === 'undefined') {
			return match;
		}

		if (typeof placeholders[key] === 'function') {
			return placeholders[key]();
		}

		return placeholders[key];
	});
}

module.exports = {
	applyPlaceholders
};
