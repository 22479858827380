function throttle(callback, limit) {
	let wait = false,
		refire = false,
		context,
		args;

	return function throttled() {
		context = this;
		args = arguments;

		if (wait) {
			refire = true;
		} else {
			callback.apply(context, args);
			wait = true;

			setTimeout(function() {
				wait = false;

				if (refire) {
					refire = false;
					throttled.apply(context, args);
				}
			}, limit);
		}
	};
}

module.exports = throttle;
