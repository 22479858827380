const creativeService = require('../../services/creatives/creative');

const authKey = Math.random().toString(36).slice(2);

function isValidOrigin(e) {
	return e.origin === window.location.origin || e.origin === 'https://acdn.adnxs.com' || e.origin === 'http://acdn.adnxs.com' || e.origin === 'https://acdn.adnxs-simple.com' || e.origin === 'http://acdn.adnxs-simple.com';
}

window.addEventListener('message', (e) => {
	if (typeof e.data !== 'string' || !isValidOrigin(e)) {
		return;
	}

	let data = (function (raw) {
		try {
			return JSON.parse(raw);
		} catch (err) {
			return false;
		}
	}(e.data));

	if (data && data.type === 'advertCallback' && typeof data.action === 'string') {
		if (data.authKey !== authKey) {
			console.error('[ADVERT] Invalid authKey on callback');

			return;
		}
		creativeService.loadCustomCreative(data);
	}
});

function getAuthKey() {
	return authKey;
}

module.exports = {
	getAuthKey
};
