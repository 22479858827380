const interscrollerCreative = require('./interscroller'),
	pubsub = require('../../utils/pubsub');

function loadCustomCreative(data) {
	if (data.action === 'showInterscroller') {
		interscrollerCreative.render(data.options);
	} else {
		pubsub.publish(data.action, data.options);
	}
}

module.exports = {
	loadCustomCreative
};
