const breakpoints = require('../../services/breakpoints'),
	sizeMappings = require('../../services/sizeMappings'),
	targeting = require('../services/targeting'),
	pubsub = require('../../utils/pubsub'),
	perf = require('../../utils/performance');

/* Prep apntag */
window.apntag = window.apntag || {};
window.apntag.anq = window.apntag.anq || [];

let externalUid = null;

const queueReady = new Promise((resolve) => {
	window.apntag.anq.push(() => {
		resolve();
	});
});

pubsub.subscribe('breakpoint.changed', async () => {
	await queueReady;

	updatePageTargeting();
});

function _hookEvents() {
	window.apntag.onEvent('adError', (error, adObj) => {
		console.error('[ADVERT] Something went wrong in an ad creative for the ad ', adObj, ' | Error: ', error);
	});
}

async function init(settings) {
	perf.mark('xandr - apntag.init - start');

	perf.mark('xandr - apntag.cmd wait - start');
	await queueReady;
	perf.mark('xandr - apntag.cmd wait - end');

	window.apntag.clearRequest();

	window.apntag.setPageOpts(_getPageOptionsFromSettings(settings));
	setUserPageOptions();
	_hookEvents();

	perf.mark('xandr - apntag.init - end');
}

function defineSlot(slot) {
	window.apntag.defineTag(_getTagValuesFromSlot(slot));
}

function _formatSizeMappingForXandr(sizeMap) {
	const sizeMaps = Object.entries(breakpoints.getBreakpoints()).map(([bpName, bpBegin]) => ({
		'minWidth': bpBegin,
		'sizes': (sizeMap[bpName] || []).filter((s) => s !== 'fluid')
	}));

	if (sizeMap.customBreakpointMapping) {
		return [...sizeMaps, ...sizeMap.customBreakpointMapping];
	}

	return sizeMaps;
}

function loadSlots(slots = []) {
	const slotPromises = slots.map((s) => _waitForSingleSlotEvent(s, ['adRequested'], ['adRequestFailure']));

	window.apntag.loadTags(slots.map((s) => s.domId));

	return slotPromises;
}

function _waitForSingleSlotEvent(slot, events, failureEvents = []) {
	function _clean() {
		events.forEach((event) => {
			window.apntag.offEvent(event, slot.domId);
		});

		failureEvents.forEach((failureEvent) => {
			window.apntag.offEvent(failureEvent, slot.domId);
		});
	}

	return new Promise((resolve, reject) => {
		events.forEach((event) => {
			window.apntag.onEvent(event, slot.domId, (...params) => {
				_clean();
				resolve(params);
			});
		});

		failureEvents.forEach((failureEvent) => {
			window.apntag.onEvent(failureEvent, slot.domId, (...params) => {
				_clean();
				reject(params);
			});
		});
	});
}

function renderSlot(slot) {
	window.apntag.modifyTag(slot.domId, _getTagValuesFromSlot(slot));
	window.apntag.showTag(slot.domId);
}

function refreshSlots(slots = []) {
	slots.forEach((slot) => {
		window.apntag.modifyTag(slot.domId, _getTagValuesFromSlot(slot));
	});

	window.apntag.refresh(slots.map((s) => s.domId));
}

function _getPageOptionsFromSettings(/** Settings */ settings) {
	externalUid = settings.user;

	return {
		'member': settings.memberId,
		'enableSafeFrame': settings.enableSafeFrame,
		'disablePsa': settings.disablePSA,
		'keywords': targeting.getPageTargeting(),
		'enableMediationEvents': true
	};
}

function setUserPageOptions(userIds = []) {
	const userPageOption = {};

	if (externalUid) {
		userPageOption.externalUid = externalUid;
	}

	if (userIds.length > 0) {
		userPageOption.userIds = userIds;
	}

	if (Object.keys(userPageOption).length > 0) {
		window.apntag.setPageOpts({'user': userPageOption});
	}
}

function updatePageTargeting() {
	window.apntag.setPageOpts({
		'keywords': targeting.getPageTargeting()
	});
}

function _getTagValuesFromSlot(/** Slot */ slot) {
	const placement = typeof slot.placement === 'object' ? slot.placement[breakpoints.getCurrentBreakpoint()] : slot.placement,
		placementKey = typeof placement === 'number' ? 'tagId' : 'invCode';

	return {
		'targetId': slot.domId,
		[placementKey]: placement,
		'sizeMapping': _formatSizeMappingForXandr(sizeMappings.getSizeMapping(slot.sizeMapping)),
		'keywords': targeting.getSlotTargeting(slot)
	};
}

function resizeSlot(slot, newSize) {
	window.apntag.resizeAd(slot.domId, newSize);
}

async function addRenderFinishListener(callback) {
	await queueReady;

	window.apntag.onEvent('adLoaded', callback);
	window.apntag.onEvent('adNoBid', callback);
	window.apntag.onEvent('adLoadedMediated', callback);
	window.apntag.onEvent('adNoBidMediated', callback);
}

module.exports = {
	init,
	defineSlot,
	loadSlots,
	renderSlot,
	refreshSlots,
	resizeSlot,
	setUserPageOptions,
	updatePageTargeting,
	addRenderFinishListener
};
