class XandrAd {
	constructor(adObj) {
		this.adType = adObj.adType;
		this.source = adObj.source;
		this.creativeId = adObj.creativeId;
		this.targetId = adObj.targetId;
		this.tagId = adObj.tagId;
		this.buyerMemberId = adObj.buyerMemberId;
		this.auctionId = adObj.auctionId;
		this.width = adObj.width;
		this.height = adObj.height;

		if (typeof adObj.nobid !== 'undefined') {
			this.isEmpty = Boolean(adObj.nobid);
		}

		this.size = typeof this.width === 'undefined' || typeof this.height === 'undefined' ? [] : [this.width, this.height];

		if (this.source !== 'csm') {
			this[this.adType] = adObj[this.adType];
		}
	}
}

module.exports = XandrAd;
