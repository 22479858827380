const pubsub = require('../utils/pubsub');

const CLOSE_BUTTON_CLASS = 'advert-closeBtn',
	SVG_CLOSE_ICON = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15 15"><path d="M3.25,3.25l8.5,8.5M11.75,3.25l-8.5,8.5"></path></svg>',
	STYLES = `
.${CLOSE_BUTTON_CLASS} {
	display: none;
	position: absolute;
	right: 4px;
	top: 4px;
	width: 20px;
	height: 20px;
	padding: 0;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 50%;
	stroke: #000;
	cursor: pointer;
}

[data-advert-slot-state="rendered"] > .${CLOSE_BUTTON_CLASS} {
	display: block;
}

[data-advert-closable="closable"] {
	background: #fafafa;
}

`.replace(/[\t\n]/g, '');

let closeableSlots = [];

_hookEvents();

function init(slotManager) {
	closeableSlots = slotManager.getSlots().filter((s) => s.closeable);

	if (closeableSlots.length > 0) {
		_addStyling();
	}
}

function _hookEvents() {
	pubsub.subscribe('slot.rendered', _onSlotRendered);
}

function _onSlotRendered(slot) {
	if (!slot.isEmpty && closeableSlots.some(s => s.name === slot.name)) {
		setTimeout(() => {
			_addCloseButtonIfMissing(slot);
		}, 100); // Minor delay because Google sometimes rushes the event
	}
}

function _addStyling() {
	const STYLE_TAG_ID = 'closable-add-styling';

	if (document.getElementById(STYLE_TAG_ID)) {
		return;
	}

	const styleTag = document.createElement('style');

	styleTag.setAttribute('id', STYLE_TAG_ID);
	styleTag.innerHTML = STYLES;
	document.head.appendChild(styleTag);
}

function _addCloseButtonIfMissing(slot) {
	if (slot.node.querySelector('.' + CLOSE_BUTTON_CLASS) === null) {
		const button = document.createElement('button');

		button.className = CLOSE_BUTTON_CLASS;
		button.setAttribute('aria-label', 'close ad');
		button.innerHTML = SVG_CLOSE_ICON;
		button.addEventListener('click', () => {
			slot.node.innerHTML = '';
			slot.node.removeAttribute('style');
			slot.node.removeAttribute('data-advert-closable');
		});
		slot.node.appendChild(button);
	}

	slot.node.setAttribute('data-advert-closable', 'closable');

	const computedPosition = window.getComputedStyle(slot.node).getPropertyValue('position');

	if (!computedPosition || computedPosition === 'static') {
		slot.node.style.position = 'relative';
	}
}

module.exports = {
	init
};
