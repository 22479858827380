const adManager = require('../../xandr/services/adManager'),
	perf = require('../../utils/performance');

function setPrebidUserIds() {
	perf.mark('prebid - getUserIds - start');

	const pbjsUserIds = window.pbjs.getUserIds(), userIds = [];

	if (pbjsUserIds.criteoId) {
		userIds.push({
			'type': 'criteo',
			'id': pbjsUserIds.criteoId
		});
	}

	if (pbjsUserIds.tdid) {
		userIds.push({
			'type': 'ttd',
			'id': pbjsUserIds.tdid
		});
	}

	if (pbjsUserIds.identityLink) {
		userIds.push({
			'type': 'liveramp',
			'id': pbjsUserIds.identityLink
		});
	}

	perf.mark('prebid - getUserIds - end');

	adManager.setUserPageOptions(userIds);
}

module.exports = {
	setPrebidUserIds
};
