/**
 * debounce utility
 */
function debounce(func, wait) {
	let timeout;

	return function executedFunction() {
		const context = this,
			args = arguments;

		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			func.apply(context, args);
		}, wait);
	};
}

module.exports = debounce;
