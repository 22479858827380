function apply(moduleConfig, config) {
	const gdprConfig = config.gdpr || {};

	if (!gdprConfig.forceDisable) {
		moduleConfig.consentManagement = {
			'gdpr': {
				'cmpApi': 'iab',
				'defaultGdprScope': true,
				'allowAuctionWithoutConsent': typeof gdprConfig.allowAuctionWithoutConsent === 'undefined' ? false : gdprConfig.allowAuctionWithoutConsent,
				'timeout': typeof gdprConfig.timeout === 'undefined' ? 8000 : gdprConfig.timeout
			}
		};
	}
}

module.exports = {
	apply
};
