const querystring = require('./querystring');

if (typeof querystring.get('advertdebug') !== 'undefined') {
	load();
}

function load() {
	if (document.body) {
		_addScriptTag();
	} else {
		document.addEventListener('DOMContentLoaded', _addScriptTag);
	}
}

function _getBaseUrl() {
	if (document.currentScript) {
		return document.currentScript.src.slice(0, document.currentScript.src.lastIndexOf('/'));
	}

	return 'https://advertising-cdn.dpgmedia.cloud/web-advertising/snapshot';
}

function _addScriptTag() {
	try {
		const baseUrl = _getBaseUrl(),
			script = document.createElement('script');

		script.src = `${baseUrl}/advert-debug.js`;
		script.async = true;
		script.addEventListener('load', () => {
			const interval = setInterval(() => {
				if (window.advert.debug) {
					window.advert.debug.start();
					clearInterval(interval);
				}
			}, 1000);
		});

		document.body.appendChild(script);
	} catch (e) {
		console.warn('[ADVERT] Could not load debug script', e);
	}
}
