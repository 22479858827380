class Settings {
	constructor(config) {
		_validatePropsExist(config, ['memberId', 'breakpoints', 'sizeMapping', 'slots']);

		if (typeof config.memberId !== 'number') {
			throw Error('memberId should be a number');
		}

		this.memberId = config.memberId;
		this.breakpoints = config.breakpoints;
		this.sizeMapping = config.sizeMapping;
		this.slots = config.slots;
		this.user = config.user;
		this.targeting = Object.assign({}, config.targeting);

		this.disablePSA = typeof config.disablePSA === 'undefined' ? true : Boolean(config.disablePSA);
		this.enableSafeFrame = typeof config.enableSafeFrame === 'undefined' ? true : Boolean(config.enableSafeFrame);

		this.lazyLoadThreshold = config.lazyLoadThreshold;

		this.loadSlotBatchCount = config.loadSlotBatchCount;
	}
}

function _validatePropsExist(obj, props) {
	if (typeof obj === 'undefined') {
		throw Error('Missing settings');
	}

	props.forEach((prop) => {
		if (typeof obj[prop] === 'undefined') {
			throw Error(`Missing required setting: ${prop}`);
		}
	});
}

module.exports = Settings;
