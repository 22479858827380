const Slot = require('../domain/Slot'),
	adManager = require('./adManager'),
	lazyLoadService = require('../../services/lazyLoadService'),
	breakpoints = require('../../services/breakpoints'),
	sizeMappings = require('../../services/sizeMappings'),
	slotsStates = require('../domain/SlotStates'),
	arrayUtils = require('../../utils/array-util'),
	pubsub = require('../../utils/pubsub'),
	perf = require('../../utils/performance'),
	nodeUtils = require('../../utils/node');

const DEFAULT_SLOT_LOAD_BATCH_COUNT = 12;

let slots = [],
	loadSlotBatchCount,
	waitingForDefine;

_hookEvents();

async function init(settings) {
	perf.mark('xandr - slotmanager.init - start');

	loadSlotBatchCount = settings.loadSlotBatchCount || DEFAULT_SLOT_LOAD_BATCH_COUNT;
	waitingForDefine = {};

	_removeSlots();

	slots = _createSlots(settings.slots);

	await defineSlots(slots.filter((slot) => slot.getPreload()));

	perf.mark('xandr - slotmanager.init - end');
}

function _hookEvents() {
	pubsub.subscribe('breakpoint.changed', _onBreakpointChange);
	pubsub.subscribe('slot.defined', _onSlotDefined);
	adManager.addRenderFinishListener(_onRenderFinish);
}

function _onBreakpointChange(currentBreakpoint) {
	return Promise.all(slots
		.filter((/** Slot*/ slot) => slot.state === slotsStates.RENDERED)
		.filter((/** Slot*/ slot) => slot.renderBreakpoint && slot.renderBreakpoint !== currentBreakpoint)
		.map((slot) => slot.refresh('breakpoint_changed')));
}

function _onRenderFinish(adObj) {
	if (!adObj) {
		return;
	}

	const slot = slots.find((s) => s.domId === adObj.targetId);

	if (slot) {
		slot.finalizeRender(adObj);
	}
}

function _isUsedOnCurrentBreakpoint(slot) {
	const sizes = sizeMappings.getSizesFromSizeMapForBreakpoint(slot.sizeMapping, breakpoints.getCurrentBreakpoint());

	return typeof sizes !== 'undefined' && sizes.length > 0;
}

function _createSlots(settingSlots) {
	return settingSlots.map((s) => {
		try {
			return new Slot(s);
		} catch (e) {
			console.error('[ADVERT] Could not create slot. Error:', e);

			return null;
		}
	}).filter(s => s !== null);
}

function _removeSlots() {
	slots.forEach((slot) => {
		slot.removeNode();
	});

	slots = [];
}

async function defineSlots(toDefine) {
	perf.mark('xandr - slotmanager.define - start');

	const definedSlots = (
		await Promise.all(
			toDefine
				.filter(_isUsedOnCurrentBreakpoint)
				.map((slot) => slot.define().catch((e) => {
					console.error('[ADVERT] Could not define slot. Error:', e);

					return null;
				}))
		)
	).filter(s => s !== null);

	perf.mark('xandr - slotmanager.extendSlots - start');
	await pubsub.publish('extendSlots', definedSlots);
	perf.mark('xandr - slotmanager.extendSlots - end');

	await _fetchSlots(definedSlots);

	perf.mark('xandr - slotmanager.define - end');
}

async function _fetchSlots(definedSlots) {
	let batchCount;

	if (typeof loadSlotBatchCount === 'object') {
		batchCount = loadSlotBatchCount[breakpoints.getCurrentBreakpoint()];
	} else {
		batchCount = loadSlotBatchCount;
	}

	const batches = arrayUtils.getBatched(definedSlots, typeof batchCount === 'number' ? batchCount : DEFAULT_SLOT_LOAD_BATCH_COUNT);

	await Promise.all(batches.map(async (batch) => {
		return (await Promise.all(adManager.loadSlots(batch).map((promise) => {
			return promise.catch((e) => {
				console.error('[ADVERT] Could not load slot. Error:', e);

				return null;
			});
		}))).filter(s => s !== null);
	}));
}

async function _waitForSlotDefine(slot) {
	if (slot.state === slotsStates.DEFINED || slot.state === slotsStates.RENDERED) {
		return;
	}

	// It should define a non-preloaded slot, but no others as those are likely broken or busy.
	if (slot.state === slotsStates.CREATED && !slot.getPreload()) {
		await defineSlots([slot]);
	} else {
		await new Promise((resolve) => {
			waitingForDefine[slot.name] = resolve;
		});
	}
}

function _onSlotDefined(slot) {
	if (typeof waitingForDefine[slot.name] === 'function') {
		waitingForDefine[slot.name]();
		delete waitingForDefine[slot.name];
	}
}

async function loadSlot(slotName, nodeId) {
	const slot = slots.find((s) => s.name === slotName),
		slotIdentifier = nodeId || slotName;

	if (!slot) {
		throw new Error(`No slot found for ${slotName}`);
	}

	const node = document.getElementById(slotIdentifier);

	if (!node) {
		// Slot already loaded for this node
		if (slot.node && slot.node.getAttribute('data-advert-orig-id') === slotIdentifier) {
			return;
		}
		throw new Error(`No DOM element found for id ${slotIdentifier}`);
	}

	if (slot.node === node) {
		return;
	}

	const previousNode = slot.node;

	slot.updateNode(node);

	await _waitForSlotDefine(slot);

	if (slot.lazyLoad) {
		await lazyLoadService.waitForSlot(slot);
	}

	if (!nodeUtils.isConnected(node)) {
		console.warn('[ADVERT] Tried rendering slot in a node that\'s no longer connected to the DOM', slot, node);

		return;
	}

	// Trying to load for a different DOM position
	if (previousNode && previousNode !== node && (slot.state === slotsStates.RENDERED || !nodeUtils.isConnected(previousNode))) {
		return slot.refresh(null); // No reason, it's not a 'real' refresh
	}

	return slot.render();
}

function getSlot(slotName) {
	return slots.find((s) => s.name === slotName);
}

function getSlots() {
	return [...slots];
}

module.exports = {
	init,
	loadSlot,
	defineSlots,
	getSlot,
	getSlots
};
