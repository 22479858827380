/*
.advert__interscroller {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

@supports (clip-path: inset(0)) {
  .advert__interscroller {
    clip-path: inset(0);
    clip: unset !important;
  }
}

.advert__interscroller_iframe {
	border: none;
	position: fixed;
	top: 0;
	height: 100vh
}

.advert__interscroller-container~* {
	transform: translate3d(0, 0, 0)
}
 */
const throttle = require('../../utils/throttle'),
	TEMPLATE = '<div class="advert__interscroller"><iframe class="advert__interscroller_iframe" sandbox="allow-scripts"></iframe></div>',
	STYLES = '.advert__interscroller{position:absolute;left:0;right:0;bottom:0;top:0}@supports (clip-path:inset(0)){.advert__interscroller{clip-path:inset(0);clip:unset!important}}.advert__interscroller_iframe{border:none;position:fixed;top:0;height:100vh}.advert__interscroller-container~*{transform:translate3d(0,0,0)}';

function _getScaledWidth(containerNode, requestedWidth) {
	const boundingClientWidth = containerNode.getBoundingClientRect().width;

	return Math.floor(requestedWidth > boundingClientWidth ? boundingClientWidth : requestedWidth);
}

function _setupStyling(slotWrapper) {
	const computedPosition = window.getComputedStyle(slotWrapper).getPropertyValue('position');

	if (!computedPosition || computedPosition === 'static') {
		slotWrapper.style.position = 'relative';
	}

	if (!document.getElementById('advert__interscroller-style')) {
		const styleTag = document.createElement('style');

		styleTag.setAttribute('id', 'advert__interscroller-style');
		styleTag.innerHTML = STYLES;

		document.head.appendChild(styleTag);
	}
}

function _setupContainer() {
	const container = document.createElement('div');

	container.setAttribute('class', 'advert__interscroller-container');
	container.innerHTML = TEMPLATE;

	return container;
}

function render(adConfig) {
	const slot = window.advert.getSlots().find((s) => s.name === adConfig.identifier);

	if (!slot || !slot.domId) {
		console.error('[ADVERT] No Slot found for interscroller');

		return;
	}

	const containingDivId = window.apntag.getTag(slot.domId).utDivId,
		slotWrapper = document.getElementById(containingDivId);

	if (!slotWrapper) {
		console.error('[ADVERT] Could not find wrapping div for interscroller', slot);

		return;
	}

	const container = _setupContainer(),
		contentContainer = container.querySelector('.advert__interscroller'),
		contentFrame = contentContainer.querySelector('.advert__interscroller_iframe');

	contentFrame.src = `data:text/html;charset=utf-8,${encodeURIComponent(adConfig['background-template'].trim())}`;

	const throttledResize = throttle(_resize, 250);

	function _resize() {
		const containingDiv = document.getElementById(containingDivId);

		if (!containingDiv || !window.advert.getSlot(slot.name)) {
			window.removeEventListener('resize', throttledResize);

			return;
		}

		const width = _getScaledWidth(slot.node, adConfig.width),
			height = adConfig.height;

		contentContainer.style.clip = `rect(0, ${width}px, ${height}px, 0)`;
		contentFrame.style.width = `${width}px`;

		containingDiv.style.width = `${width}px`;
		containingDiv.style.height = `${height}px`;
		window.apntag.resizeAd(slot.domId, [width, height]);
	}

	_resize();
	window.addEventListener('resize', throttledResize);

	_setupStyling(slotWrapper);
	slotWrapper.insertBefore(container, slotWrapper.firstChild);
}

module.exports = {
	render
};
