const mediaTypes = require('./mediaTypes'),
	bidders = require('./bidderService');

function getAdUnit(config, slot) {
	if (typeof config.slots === 'undefined' || typeof config.slots[slot.name] === 'undefined') {
		return null;
	}

	const mt = mediaTypes.getMediaTypes(config, slot),
		bids = bidders.getBidders(config, slot);

	if (Object.keys(mt).length === 0 || bids.length === 0) {
		return null;
	}

	return {
		'code': slot.domId,
		'mediaTypes': mt,
		bids
	};
}

module.exports = {
	getAdUnit
};
