const perf = require('./performance');

let queue = [],
	accepted = [];

function setup(initialQueue = [], initialAccepted = []) {
	perf.mark('core - cmd - initialisation - start');

	accepted = initialAccepted;
	queue = initialQueue.map(_normalizeCommand);
	_runQueue();

	perf.mark('core - cmd - initialisation - end');
}

function _normalizeCommand(rawCommand) {
	let command = {};

	if (typeof rawCommand === 'function') {
		command.fn = rawCommand;
	}

	if (Array.isArray(rawCommand)) {
		if (typeof rawCommand[0] === 'function') {
			command.fn = rawCommand[0];
		} else {
			command.category = rawCommand[0];
			command.fn = rawCommand[1];
		}
	}

	if (typeof command.fn !== 'function') {
		console.error('core.cmd - Invalid command format: ', rawCommand);
	}

	return command;
}

function _exec(cmd) {
	try {
		cmd.fn.call();
	} catch (e) {
		console.error('core.cmd - Failure running command.', e);
	}
}

function _runQueue() {
	for (let i = 0; i < queue.length;) {
		const cmd = queue[i];

		if (cmd.category && accepted.indexOf(cmd.category) === -1) {
			i++;
			continue;
		}

		queue.splice(i, 1);
		_exec(cmd);
	}
}

/**
 * push a new function to the queue stack and invoke it
 *
 * @param {[?string, function]} command Array with as first element the category and as second the callback. For backwards compatibility, if just a
 * function is passed as command, it's executed as if no category was given.
 */
function push(command) {
	queue.push(_normalizeCommand(command));
	_runQueue();
}

/**
 * Accept a category
 * @param {string} category Category name
 */
function accept(category) {
	accepted.push(category);
	_runQueue();
}

/**
 * Reject a category
 * @param {string} category Category name
 */
function reject(category) {
	accepted = accepted.filter((cat) => cat !== category);
}

module.exports = {
	setup,
	push,
	accept,
	reject
};
