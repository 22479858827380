const Settings = require('./domain/Settings'),
	adManager = require('./services/adManager'),
	breakpoints = require('../services/breakpoints'),
	sizeMappings = require('../services/sizeMappings'),
	slotManager = require('./services/slotManager'),
	lazyLoadService = require('../services/lazyLoadService'),
	closeableAdManager = require('../services/closeableAdManager'),
	targeting = require('./services/targeting'),
	hooks = require('../utils/hooks'),
	cmd = require('../utils/cmd'),
	pubsub = require('../utils/pubsub'),
	prebid = require('../prebid/index'),
	prebidService = require('../prebid/prebidService'),
	perf = require('../utils/performance');

require('./services/messaging');
require('../utils/debug');

let settings = null;

if (window.advert && typeof window.advert.init === 'function') {
	throw new Error('[ADVERT] web advertising script already loaded');
}

async function init(config, userHooks) {
	cmd.reject('loaded');

	const {
		'xandr': xandrConfig,
		'prebid': prebidConfig
	} = config;

	_coreInit(userHooks);

	_prebidInit(prebidConfig);

	await _xandrInit(xandrConfig);

	cmd.accept('loaded');
}

function _coreInit(userHooks = {}) {
	perf.mark('core - initialisation - start');

	hooks.init(userHooks);
	window.advert.addHook = hooks.add;
	window.advert.removeHook = hooks.remove;

	perf.mark('core - initialisation - end');
}

function _prebidInit(config) {
	prebid.init(config);
}

async function _xandrInit(config) {
	perf.mark('xandr - initialisation - start');

	settings = new Settings(config);

	lazyLoadService.init(settings);
	breakpoints.init(settings);
	targeting.init(settings);
	sizeMappings.init(settings); // Depends on breakpoints
	await adManager.init(settings); // Depends on sizeMappings & breakpoints
	await slotManager.init(settings); // Depends on ad manager
	closeableAdManager.init(slotManager);

	await pubsub.publish('settings.changed');

	perf.mark('xandr - initialisation - end');
}

const initialCmds = window.advert && window.advert.cmd || [];

window.advert = Object.assign({}, window.advert, {
	init,
	pubsub,
	cmd,
	'loadSlot': slotManager.loadSlot,
	'getSlot': slotManager.getSlot,
	'getSlots': slotManager.getSlots,
	'defineSlots': slotManager.defineSlots,
	'refreshSlots': adManager.refreshSlots,
	'getCurrentBreakpoint': breakpoints.getCurrentBreakpoint,
	'getSizeMapping': sizeMappings.getSizeMapping,
	'getSizesFromSizeMapForBreakpoint': sizeMappings.getSizesFromSizeMapForBreakpoint,
	'getBidResults': prebidService.getBidResults,
	'getPageTargeting': targeting.getPageTargeting,
	'getSlotTargeting': targeting.getSlotTargeting,
	'getSettings': () => settings,
	'updatePageTargeting': (pageTargeting) => {
		targeting.setPageTargeting(pageTargeting);
		adManager.updatePageTargeting();
	}
});

cmd.setup(initialCmds, ['ready']);
