const debounce = require('../utils/debounce'),
	throttle = require('../utils/throttle'),
	pubsub = require('../utils/pubsub');

window.addEventListener('scroll', throttle(_scroll, 250));
window.addEventListener('resize', debounce(_resize, 250));
window.addEventListener('orientationchange', debounce(_resize, 250));

function _scroll() {
	pubsub.publish('scroll', window.scrollY);
}

function _resize() {
	pubsub.publish('resize', getSize().width);
}

function getSize() {
	return {
		'height': window.innerHeight || document.documentElement.clientHeight,
		'width': window.innerWidth || document.documentElement.clientWidth
	};
}

function isInView(node, threshold = 0) {
	if (!node || !(node instanceof Element)) {
		throw new Error('Invalid argument for isInView, argument 1 is not an Element');
	}

	if (_isHidden(node)) {
		return false;
	}

	let rect = node.getBoundingClientRect(),
		screenSize = getSize();

	return rect.bottom + threshold >= 0 &&
		rect.right + threshold >= 0 &&
		rect.top - threshold <= screenSize.height &&
		rect.left - threshold <= screenSize.width;
}

function _isHidden(node) {
	for (let el = node; el; el = el.parentElement) {
		const style = getComputedStyle(el);

		if (style.display === 'none') {
			return true;
		}
	}

	return false;
}

module.exports = {
	getSize,
	isInView
};
