const util = require('./util');

module.exports = (config, slot, slotBidderCfg) => {
	const {
		targetingFields = [],
		...params
	} = util.getParamsFromConfig(config, slotBidderCfg);

	params.inventory = util.getTargeting(slot, targetingFields);

	return {
		'bidder': 'rubicon',
		params
	};
};
