const {getSlotTargeting, getPageTargeting} = require('../../xandr/services/targeting');

function getParamsFromConfig(config, slotBidderCfg) {
	const allBiddersCfg = config.bidders || {},
		bidderCfg = allBiddersCfg[slotBidderCfg.bidder] || {};

	return Object.assign({}, bidderCfg, slotBidderCfg.params);
}

function getTargeting(slot, fields = []) {
	const targeting = Object.assign({}, getPageTargeting(), getSlotTargeting(slot));

	return Object.keys(targeting)
		.filter((field) => fields.indexOf(field) !== -1)
		.reduce((t, key) => {
			t[key] = targeting[key];

			return t;
		}, {});
}

module.exports = {
	getParamsFromConfig,
	getTargeting
};
