/**
 * Pubsub hooking system
 */

/**
 * Topics
 *
 * @type {Object}
 */
const topics = {};

/**
 * Subscribe to a topic
 *
 * @param {string|string[]} topic Topic(s) to subscribe to
 * @param {Function} callback
 */
function subscribe(topic, callback) {
	if (typeof callback !== 'function') {
		console.warn('pubsub.subscribe - callback is not of type function.');

		return;
	}

	if (typeof topic === 'object' && topic instanceof Array) {
		for (let i = 0; i < topic.length; i++) {
			_subscribeTopic(topic[i], callback);
		}
	} else {
		_subscribeTopic(topic, callback);
	}
}

function _subscribeTopic(topic, callback) {
	if (typeof topic !== 'string') {
		console.warn('pubsub.subscribe - topic is not of type string.');

		return;
	}

	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		topics[topic] = [];
	}

	topics[topic].push(callback);
}

/**
 * Publish a topic. Calls callbacks asynchronously in new tasks.
 *
 * @param {string} topic
 * @param args
 */
function publish(topic, ...args) {
	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		return Promise.resolve();
	}

	return Promise.all(topics[topic].map(async (cb) => {
		try {
			return await cb(...args);
		} catch (e) {
			console.error(`[ADVERT] Something went wrong calling publishing ${topic}`, e);
		}
	}));
}

/**
 * unpublish a topic
 *
 * @param topic
 * @param callback
 */
function unsubscribe(topic, callback) {
	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		return;
	}

	if (typeof callback === 'undefined') {
		topics[topic] = [];
	} else {
		_unsubscribeSingleCallback(topic, callback);
	}
}

function _unsubscribeSingleCallback(topic, callback) {
	const index = topics[topic].indexOf(callback);

	if (index === -1) {
		return;
	}

	topics[topic].splice(index, 1);
}

/**
 * Lists all topics
 * @param {string} [topic] Optional subscription topic. If no topic is given, all topics are returned.
 * @returns {Object|Array} Array of callbacks for topic, or Object with all topics.
 * @private
 */
function listSubscriptions(topic) {
	return typeof topic === 'undefined' ? Object.assign({}, topics) : Array.isArray(topics[topic]) ? topics[topic].slice() : [];
}

module.exports = {
	subscribe,
	publish,
	unsubscribe,
	listSubscriptions
};
