const prebidService = require('./prebidService'),
	pubsub = require('../utils/pubsub'),
	perf = require('../utils/performance');

let config;

pubsub.subscribe('extendSlots', _extendSlots);

function init(cfg) {
	if (typeof cfg === 'object' && Object.keys(cfg).length > 0) {
		perf.mark('prebid - initialisation');

		config = cfg;
	} else {
		config = null;
	}
}

function _extendSlots(slots) {
	if (config) {
		return prebidService.getBids(config, slots).catch((err) => {
			console.error('[ADVERT] Something went wrong getting prebid bids.', err);
		});
	}
}

module.exports = {
	init
};
