const breakpoints = require('../../services/breakpoints'),
	querystring = require('../../utils/querystring'),
	pubsub = require('../../utils/pubsub'),
	messaging = require('./messaging');

let pageTargeting;

function init(settings) {
	setPageTargeting(settings.targeting);
}

function _getMetaTargeting() {
	const metaTargeting = {
			'screen': breakpoints.getCurrentBreakpoint(),
			'authKey': messaging.getAuthKey()
		},
		adops = querystring.get('adops');

	if (adops) {
		metaTargeting.adops = adops;
	}

	return metaTargeting;
}

function _getTargeting(targeting) {
	return Object.assign({}, _getMetaTargeting(), _getSafeTargeting(targeting));
}

function setPageTargeting(targeting) {
	pageTargeting = _getTargeting(targeting);

	pubsub.publish('settings.changed');
}

function getPageTargeting() {
	return _replacePlaceholders(_getTargeting(pageTargeting));
}

function getSlotTargeting(/** Slot */ slot) {
	const slotTargeting = {
		'slot': slot.name,
		...slot.targeting
	};

	return _replacePlaceholders(_getSafeTargeting(slotTargeting), slot);
}

function _getSafeTargeting(targeting) {
	return Object.keys(targeting).reduce((t, key) => {
		let value = _getSafeTargetValue(key, targeting[key]);

		if (value !== null) {
			t[key] = value;
		}

		return t;
	}, {});
}

function _getSafeTargetValue(key, value) {
	if (typeof value === 'string') {
		return value;
	}

	if (typeof value === 'boolean' || typeof value === 'number') {
		return value.toString();
	}

	if (Array.isArray(value)) {
		return value.map((val, idx) => {
			if (Array.isArray(val)) {
				return null;
			}

			return _getSafeTargetValue(`${key}.${idx}`, val);
		}).filter((val) => val !== null);
	}

	return null;
}

function _replacePlaceholders(targeting, slot) {
	Object.keys(targeting).forEach((field) => {
		if (typeof targeting[field] === 'string') {
			targeting[field] = targeting[field].replace('%screenSize%', breakpoints.getCurrentBreakpoint());

			if (slot) {
				targeting[field] = targeting[field].replace('%slotName%', slot.name);
			}
		}
	});

	return targeting;
}

module.exports = {
	init,
	getPageTargeting,
	getSlotTargeting,
	setPageTargeting
};
