const {getPageTargeting} = require('../../xandr/services/targeting');

function apply(moduleConfig) {
	if (moduleConfig.floors) {
		moduleConfig.floors.additionalSchemaFields = _getAdditionalSchemaFieldGetters(moduleConfig.floors);
	}
}

// Defaults schema fields are gptSlot, adUnitCode, mediaType, size
function _getAdditionalSchemaFieldGetters(floorConfig) {
	const targetingFields = floorConfig.data?.schema?.fields?.filter((f) => f.startsWith('targeting:')) ?? [],
		targetingGetters = _getTargetingGetters(targetingFields);

	return {
		'bidder': _getFromBidResponse('bidder'),
		'width': _getFromBidResponse('width'),
		'height': _getFromBidResponse('height'),
		'currency': _getFromBidResponse('currency'),
		'creativeId': _getFromBidResponse('creativeId'),
		...targetingGetters
	};
}

function _getFromBidResponse(field) {
	return (bidResponse) => bidResponse[field] ? `${bidResponse[field]}` : '*';
}

function _getTargetingGetters(fields) {
	return fields.reduce((agg, field) => {
		const targetingKey = field.slice('targeting:'.length);

		agg[field] = () => {
			const pageTargeting = getPageTargeting();

			return `${pageTargeting[targetingKey] ?? '*'}`;
		};

		return agg;
	}, {});
}

module.exports = {
	apply
};
