const pubsub = require('./pubsub');

const registeredHooks = {};

/**
 * subscribe all listed hooks in the config
 */
function init(hooks) {
	Object.keys(registeredHooks).forEach((registeredHook) => {
		remove(registeredHook, registeredHooks[registeredHook]);
		delete registeredHooks[registeredHook];
	});

	Object.keys(hooks).forEach((hook) => {
		add(hook, hooks[hook]);
		registeredHooks[hook] = hooks[hook];
	});
}

function _createLegacyHookPassthrough(hook) {
	window.dfp = window.dfp || {};

	if (typeof window.dfp[hook] === 'undefined') {
		window.dfp[hook] = (...args) => {
			pubsub.publish(hook, ...args);
		};
	}
}

function add(hook, callback) {
	pubsub.subscribe(hook, callback);

	_createLegacyHookPassthrough(hook);
}

function remove(hook, callback) {
	pubsub.unsubscribe(hook, callback);

	_removeLegacyHookPassthrough(hook);
}

function _removeLegacyHookPassthrough(hook) {
	if (window.dfp && typeof window.dfp[hook] === 'function') {
		delete window.dfp[hook];
	}
}

module.exports = {
	init,
	add,
	remove
};
