const def = require('./default'),
	rubicon = require('./rubicon'),
	improvedigital = require('./improvedigital'),
	appnexus = require('./appnexus');

module.exports = {
	'default': def,
	rubicon,
	improvedigital,
	'imprweborama': improvedigital,
	appnexus,
	'gps': appnexus
};
