function getBatched(arr, batchSize) {
	const batchCount = Math.ceil(arr.length / batchSize);

	let batches = [];

	for (let i = 0; i < batchCount; i++) {
		batches.push(arr.slice(i * batchSize, (i + 1) * batchSize));
	}

	return batches;
}

module.exports = {
	getBatched
};
