const screenUtil = require('../utils/screen'),
	pubsub = require('../utils/pubsub'),
	breakpoints = require('./breakpoints'),
	DEFAULT_LAZYLOAD_THRESHOLD = 300;

let lazyLoadThreshold = DEFAULT_LAZYLOAD_THRESHOLD,
	waitingForInView = [];

pubsub.subscribe('scroll', _resolveInViewSlots);

function init(/** Settings */ settings) {
	lazyLoadThreshold = settings.lazyLoadThreshold;
	waitingForInView = [];
}

function waitForSlot(/** Slot */ slot) {
	return new Promise((resolve) => {
		if (_isInView(slot)) {
			resolve(slot);
		} else {
			waitingForInView.push({
				slot,
				resolve
			});
		}
	});
}

function _isInView(slot) {
	const threshold = _getThresholdForSlot(slot);

	return screenUtil.isInView(slot.node, threshold);
}

function _getThresholdForSlot(/** Slot */ slot) {
	let thresholdValue = _getValueFromThreshold(slot.lazyLoadThreshold);

	if (!_isValidTresholdValue(thresholdValue)) {
		thresholdValue = _getValueFromThreshold(lazyLoadThreshold);
	}

	if (!_isValidTresholdValue(thresholdValue)) {
		thresholdValue = DEFAULT_LAZYLOAD_THRESHOLD;
	}

	return typeof thresholdValue === 'string' ? _getScreenRatioValue(thresholdValue) : thresholdValue;
}

function _getScreenRatioValue(ratio) {
	const percent = parseInt(ratio.slice(0, -1), 10),
		screenHeight = screenUtil.getSize().height;

	return screenHeight * percent / 100;
}

function _getValueFromThreshold(threshold) {
	if (typeof threshold === 'object' && threshold !== null) {
		const currentBp = breakpoints.getCurrentBreakpoint();

		return _isValidTresholdValue(threshold[currentBp]) ? threshold[currentBp] : threshold.default;
	}

	return threshold;
}

function _isValidTresholdValue(thresholdValue) {
	if (typeof thresholdValue === 'string') {
		return /^[0-9]+%$/.test(thresholdValue);
	}

	if (typeof thresholdValue === 'number') {
		return !isNaN(thresholdValue) && thresholdValue >= 0;
	}

	return false;
}

function _resolveInViewSlots() {
	waitingForInView = waitingForInView.filter(({
		slot,
		resolve
	}) => {
		if (_isInView(slot)) {
			resolve(slot);

			return false;
		}

		return true;
	});
}

module.exports = {
	init,
	waitForSlot
};
