const adUnits = require('./adUnits'),
	{getModuleConfig} = require('./modules'),
	{setPrebidUserIds} = require('./modules/userIds'),
	perf = require('../utils/performance');

window.pbjs = window.pbjs || {};
window.pbjs.que = window.pbjs.que || [];

const DEFAULT_TIMEOUT = 8000;
let bidResults = [];

function _waitForPrebid(config) {
	return new Promise((resolve, reject) => {
		let timeout = setTimeout(() => {
			reject(new Error('Timed out waiting for Prebid.js to load'));
		}, config.timeout || DEFAULT_TIMEOUT);

		window.pbjs.que.push(() => {
			clearTimeout(timeout);
			resolve();
		});
	});
}

function _requestBids(config) {
	return new Promise((resolve) => {
		window.pbjs.requestBids({
			'bidsBackHandler': (...args) => {
				bidResults.push(args);
				resolve();
			},
			'timeout': config.timeout || DEFAULT_TIMEOUT
		});
	});
}

async function getBids(config, slots) {
	perf.mark('prebid - getBids - start');

	await _waitForPrebid(config);

	const units = slots.filter(s => s.state === 'defined').map((slot) => adUnits.getAdUnit(config, slot)).filter(a => a !== null);

	if (units.length > 0) {
		window.pbjs.setConfig(getModuleConfig(config));
		window.pbjs.aliasBidder('improvedigital', 'imprweborama');
		window.pbjs.aliasBidder('appnexus', 'gps');
		window.pbjs.addAdUnits(units);

		perf.mark('prebid - requestBids - start');
		await _requestBids(config);
		perf.mark('prebid - requestBids - end');

		window.pbjs.setTargetingForAst();
		setPrebidUserIds();
	}

	perf.mark('prebid - getBids - end');
}

function getBidResults() {
	return bidResults;
}

module.exports = {
	getBids,
	getBidResults
};
