const {getCurrentBreakpoint} = require('../services/breakpoints');

function getMediaTypes(config, slot) {
	const mediaTypes = {},
		banner = _getBanner(config, slot);

	if (banner) {
		mediaTypes.banner = banner;
	}

	return mediaTypes;
}

function _getBanner(config, slot) {
	const sizeCfg = config.slots[slot.name].sizes;
	let sizes;

	if (Array.isArray(sizeCfg)) {
		sizes = sizeCfg;
	} else {
		sizes = sizeCfg[getCurrentBreakpoint()];
	}

	if (!Array.isArray(sizes) || sizes.length === 0) {
		return null;
	}

	return {
		sizes
	};
}

module.exports = {
	getMediaTypes
};
