/**
 * localstorage / cookie retriever
 */

/**
 * Get value from localstorage or cookie
 *
 * @param key
 * @returns {string}
 */
function retrieve(key) {
	if (window.localStorage && typeof window.localStorage[key] !== 'undefined') {
		return window.localStorage[key];
	} else if (navigator.cookieEnabled) {
		const cookieArr = document.cookie.match(key + '=([^;]*)');

		return cookieArr === null ? '' : cookieArr[1];
	}

	return '';
}

/**
 * Get value as array
 *
 * @param key
 * @returns {Array}
 */
function retrieveArray(key) {
	let arr = retrieve(key);

	return arr === '' ? [] : decodeURIComponent(arr).split(',');
}

module.exports = {
	'get': retrieve,
	'getArray': retrieveArray
};
