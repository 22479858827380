const breakpoints = require('../../services/breakpoints'),
	sizeMappings = require('../../services/sizeMappings'),
	adManager = require('../services/adManager'),
	pubsub = require('../../utils/pubsub'),
	slotsStates = require('./SlotStates'),
	perf = require('../../utils/performance'),
	XandrAd = require('./XandrAd'),
	ORIGINAL_ID_ATTRIBUTE = 'data-advert-orig-id',
	STATE_ATTRIBUTE = 'data-advert-slot-state',
	CLOSABLE_ATTRIBUTE = 'data-advert-closable',
	IS_EMPTY_ATTRIBUTE = 'data-advert-slot-empty';

function _validateSlotConfig(slotConfig) {
	function _validatePlacement(placement) {
		if (typeof placement !== 'string' && typeof placement !== 'number') {
			throw new Error('\'placement\' is required and should be a number, string or an object containing numbers or strings for each breakpoint.');
		}
	}

	if (typeof slotConfig.name !== 'string') {
		throw new Error('\'name\' is required and should be a string in slot config');
	}

	if (typeof slotConfig.placement === 'object') {
		Object.keys(breakpoints.getBreakpoints()).forEach((bp) => {
			_validatePlacement(slotConfig.placement[bp]);
		});
	} else {
		_validatePlacement(slotConfig.placement);
	}

	if (typeof slotConfig.sizeMapping !== 'string') {
		throw new Error('\'sizeMapping\' is required and should be a string inn slot config');
	}
}

function _sizesMatch(a, b) {
	return (a[0] === b[0] || a[0] === '*') && (a[1] === b[1] || a[1] === '*');
}

class Slot {
	constructor(slotConfig) {
		_validateSlotConfig(slotConfig);

		this.name = slotConfig.name;
		this.domId = _generateRandomSuffix(this.name);
		this.placement = slotConfig.placement;
		this.sizeMapping = slotConfig.sizeMapping;
		this.sizeRemapping = slotConfig.sizeRemapping;
		this.targeting = Object.assign({}, slotConfig.targeting);
		this.preload = typeof slotConfig.preload === 'undefined' ? true : slotConfig.preload;
		this.closeable = Boolean(slotConfig.closeable);
		this.node = null;
		this.renderBreakpoint = null;

		this.lazyLoad = Boolean(slotConfig.lazyLoad);
		this.lazyLoadThreshold = slotConfig.lazyLoadThreshold;

		this._updateState(slotsStates.CREATED);
	}

	_updateState(newState) {
		this.state = newState;

		if (this.node) {
			this.node.setAttribute(STATE_ATTRIBUTE, this.state);
		}

		perf.mark(`xandr - slot - ${this.name} - ${newState}`);

		pubsub.publish(`slot.${newState}`, this);
		pubsub.publish(`slot.${this.name}`, this);
		pubsub.publish(`slot.${this.name}.${newState}`, this);
	}

	_updateRenderData(/** XandrAd */ xandrAd) {
		Object.assign(this, xandrAd);
		this.renderBreakpoint = breakpoints.getCurrentBreakpoint();

		if (this.isEmpty) {
			this.node.setAttribute(IS_EMPTY_ATTRIBUTE, '');
		} else {
			this.node.removeAttribute(IS_EMPTY_ATTRIBUTE);
		}
	}

	_remapSize() {
		const sizeRemapping = this.sizeRemapping ?? sizeMappings.getSizeMapping(this.sizeMapping)?.sizeRemapping;

		if (sizeRemapping) {
			sizeRemapping[this.adType]?.forEach(([before, after]) => {
				if (_sizesMatch(before, this.size)) {
					adManager.resizeSlot(this, after);
					this.originalSize = this.size;
					this.width = after[0];
					this.height = after[1];
					this.size = [this.width, this.height];
				}
			});
		}
	}

	getPreload() {
		// Breakpoint based preload
		if (typeof this.preload === 'object') {
			const currentBp = breakpoints.getCurrentBreakpoint();

			return typeof this.preload[currentBp] === 'undefined' ? this.preload.default : this.preload[currentBp];
		}

		return this.preload;
	}

	async define() {
		perf.mark(`xandr - slot - ${this.name} - definining`);

		if (this.state !== slotsStates.CREATED) {
			const curState = this.state;

			this._updateState(slotsStates.ERROR);

			throw new Error(`slot.define - ${this.name} should be initialized first! State: ${curState}`);
		}

		await adManager.defineSlot(this);

		this._updateState(slotsStates.DEFINED);

		return this;
	}

	updateNode(node) {
		this.removeNode();
		this.node = node;
		this.node.setAttribute(ORIGINAL_ID_ATTRIBUTE, this.node.id);
		this.node.setAttribute(STATE_ATTRIBUTE, this.state);
		this.node.id = this.domId;

		return this;
	}

	removeNode() {
		if (this.node) {
			this.node.id = this.node.getAttribute(ORIGINAL_ID_ATTRIBUTE);
			this.node.removeAttribute(ORIGINAL_ID_ATTRIBUTE);
			this.node.removeAttribute(IS_EMPTY_ATTRIBUTE);
			this.node.removeAttribute(STATE_ATTRIBUTE);
			this.node.removeAttribute(CLOSABLE_ATTRIBUTE);
			this.node.innerHTML = '';
			this.node = null;
		}
	}

	render() {
		perf.mark(`xandr - slot - ${this.name} - rendering`);

		if (this.state === slotsStates.RENDERED) {
			if (this.isEmpty) {
				return;
			}

			throw new Error(`slot.render - ${this.name} is already rendered!`);
		}

		if (!this.node) {
			this._updateState(slotsStates.ERROR);

			throw new Error(`slot.render - ${this.name} doesn't have a node linked yet and could not be rendered.`);
		}

		adManager.renderSlot(this);

		return this;
	}

	refresh(reason = 'other') {
		perf.mark(`xandr - slot - ${this.name} - refreshing`);

		if (this.state === slotsStates.CREATED) {
			const curState = this.state;

			this._updateState(slotsStates.ERROR);

			throw new Error(`slot.refresh - ${this.name} should be rendered first! State: ${curState}`);
		}

		this.targeting.refresh = reason;

		adManager.refreshSlots([this]);

		return this;
	}

	finalizeRender(adObj) {
		this._updateRenderData(new XandrAd(adObj));
		this._remapSize();
		this._updateState(slotsStates.RENDERED);
	}
}

function _generateRandomSuffix(base) {
	return `${base}_${Math.round(Math.random() * 1e18)}`;
}

module.exports = Slot;
