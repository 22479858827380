const consentManagementModule = require('./consentManagement'),
	priceFloorsModule = require('./priceFloors'),
	{applyPlaceholders} = require('../../utils/placeholder'),
	storageUtil = require('../../utils/storage');

function _getPlaceholders() {
	return {
		'PWID': () => storageUtil.get('authId'),
		'domain': window.location.hostname
	};
}

function getModuleConfig(config) {
	const prebidModuleConfig = JSON.parse(JSON.stringify(config.additionalConfig || {}));

	consentManagementModule.apply(prebidModuleConfig, config);
	priceFloorsModule.apply(prebidModuleConfig, config);

	return applyPlaceholders(prebidModuleConfig, _getPlaceholders());
}

module.exports = {
	getModuleConfig
};
